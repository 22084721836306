import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { IUser } from './user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: BehaviorSubject<IUser | null> =
    new BehaviorSubject<IUser | null>(null);

  constructor() {}

  /**
   * Setter & getter for user
   *
   * @param value
   */
  set user(value: IUser) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<IUser | null> {
    return this._user.asObservable();
  }

  get userValue(): IUser | null {
    return this._user.value;
  }
}
